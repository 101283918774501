
import {defineComponent, ref} from 'vue';
import Markdown from '@/components/common/Markdown.vue';
import Media from '@/models/Media';
import Pdf from '@/components/common/Pdf.vue';
import Resource from '@/models/Resource';
import authStore from '@/store/auth';

export default defineComponent({
    components: {
        Markdown,
        Pdf,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const {me} = authStore;

        return {
            me,
            currentPage: ref<number>(1),
            totalPages: ref<number>(1),
            resource: ref<Resource>(new Resource({
                id: Number(props.id),
            })),
        };
    },
    computed: {
        canDecrement(): boolean {
            return this.currentPage > 1;
        },
        canIncrement(): boolean {
            return this.currentPage < this.totalPages;
        },
        currentTip(): Resource | undefined {
            if (!this.resource.resources) {
                return undefined;
            }

            return this.resource.resources.firstWhere(
                'data.pageNumber',
                this.currentPage.toString(),
            );
        },
        pdfMedia(): Media | undefined {
            return this.resource.media.firstWhere('mimeType', 'application/pdf');
        },
    },
    created() {
        this.resource.fetch();
    },
    methods: {
        goToAcademyDecks() {
            this.$router.push({
                name: 'academy',
                query: {
                    view: 'decks',
                },
            });
        },
        deviatePage(deviation: number) {
            if (
                (deviation > 0 && this.canIncrement)
                || (deviation < 0 && this.canDecrement)
            ) {
                this.currentPage += deviation;
            }
        },
    },
});
