import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "min-vh-100 bg-light" }
const _hoisted_2 = { class: "row g-0 align-items-center bg-white px-2 py-4 mb-6 border-bottom" }
const _hoisted_3 = { class: "col-1" }
const _hoisted_4 = { class: "ms-6" }
const _hoisted_5 = { class: "col-10 text-center" }
const _hoisted_6 = { class: "px-12" }
const _hoisted_7 = {
  key: 0,
  class: "row g-5"
}
const _hoisted_8 = { class: "col-9" }
const _hoisted_9 = { class: "d-flex justify-content-center align-items-center" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = { class: "text-center min-w-100px" }
const _hoisted_12 = ["disabled"]
const _hoisted_13 = { class: "col-3" }
const _hoisted_14 = {
  key: 0,
  class: "card mt-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!
  const _component_Pdf = _resolveComponent("Pdf")!
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_WaitForResource = _resolveComponent("WaitForResource")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          class: "btn btn-white",
          "data-test-id": "btn-back-to-decks",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToAcademyDecks && _ctx.goToAcademyDecks(...args)))
        }, [
          _createVNode(_component_Fa, { icon: "arrow-left" }),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('actions.back')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.resource.title), 1)
      ])
    ]),
    _createVNode(_component_WaitForResource, { resource: _ctx.resource }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          (_ctx.pdfMedia)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_Pdf, {
                    class: "mb-6",
                    page: _ctx.currentPage,
                    src: _ctx.pdfMedia.url,
                    onNumpages: _cache[1] || (_cache[1] = ($event: any) => (_ctx.totalPages = $event))
                  }, null, 8, ["page", "src"]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("button", {
                      class: "btn btn-circle btn-white no-focus",
                      disabled: !_ctx.canDecrement,
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deviatePage(-1)))
                    }, [
                      _createVNode(_component_Fa, { icon: "chevron-left" })
                    ], 8, _hoisted_10),
                    _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.currentPage) + " / " + _toDisplayString(_ctx.totalPages), 1),
                    _createElementVNode("button", {
                      class: "btn btn-circle btn-white no-focus",
                      disabled: !_ctx.canIncrement,
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.deviatePage(1)))
                    }, [
                      _createVNode(_component_Fa, { icon: "chevron-right" })
                    ], 8, _hoisted_12)
                  ])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("h4", null, _toDisplayString(_ctx.$t('resources.index.tips')), 1),
                  (_ctx.currentTip)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                        _createVNode(_component_Markdown, {
                          class: "card-body",
                          source: _ctx.currentTip.body
                        }, null, 8, ["source"])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["resource"])
  ]))
}